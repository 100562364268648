/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}

			/*
			Set: Menu position
			*/
			if(_fixed_menu == 0) {
				_menu_position = $('.js-menu').offset().top;
			}

			// document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {

			/*
			Scrolled navigation make sticky
			*/
			if(
				scrolled > _menu_position
			) {

				console.log('Yes');

				if(_fixed_menu == 0) {

					$('.js-menu').addClass('sticky');
					_fixed_menu = 1;
				}

			} else {

				console.log('No');

				if(_fixed_menu == 1) {
					$('.js-menu').removeClass('sticky');
					_fixed_menu = 0;
				}

			}
			
		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};

		this.isInView = function (
			_element,
			_position
		){

			/*
			Check if already triggered
			*/
			if(!_element.hasClass('triggered')) {

				/*
				Is Inview?
				*/
				if(_position.top < window.innerHeight && _position.bottom >= 0) {

					var _element_name	= _element.data('viewelement');

					// if(_element_name == 'footer') {

					// 	$('.js-denktank').stop().animate({
					// 		'right':'5vw'
					// 	},1000);

					// }

					_element.addClass("triggered");

				}

			}

		};
		
}).apply(specific_interactions_window); 